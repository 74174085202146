import React, { useState, useEffect } from "react";

const artworksData = [
	{
		id: 1,
		images: [
			"img/recognition1.webp",
			"img/recognition2.webp"
		],
		title: "Recognition: capture(scene)",
		description: {
			ja: "パノラマ写真は、1840年代に考案されたスイングレンズ方式のカメラを起源とし、スマートフォンの普及に伴い、現在では身近なものとなった。通常のパノラマ写真は、人がカメラを手で動かしながら撮影し、細長い1枚の画像を生成する。しかし、この作品ではiPhoneを固定したまま撮影している。iPhoneの前にあるディスプレイでは画像が単に右から左に流れているだけだが、iPhone越しに見るとパノラマ写真が撮影されている。撮影後に表示される結果は、鑑賞者が実際に見ているものとパノラマ画像との間に「ずれ」を生じさせる。iPhoneには特別な技術的操作は加えておらず、ディスプレイ上に再生される映像は、iOS特有の撮影アルゴリズムとスリットスキャンを応用し、スキャンのタイミングを調整することで撮影が可能になっている。この作品は、鑑賞者が普段何気なく使っている機能を誤用し、作品を通じて新たな視覚体験やものの見方を促す試みである。",
			en: "Panoramic photography originated from the swing lens camera invented in the 1840s, and has become familiar today with the spread of smartphones. A normal panoramic photograph is taken by moving the camera by hand, generating a single long and narrow image. However, in this work, the iPhone is fixed in place. On the display in front of the iPhone, the image simply flows from right to left, but when viewed through the iPhone, a panoramic photograph is taken. The result displayed after shooting creates a 'gap' between what the viewer actually sees and the panoramic image. No special technical operations were performed on the iPhone, and the image played on the display was taken by applying iOS-specific shooting algorithms and slit scanning, and adjusting the timing of the scan. This work is an attempt to misuse functions that viewers usually use casually, and encourage new visual experiences and ways of looking at things through the work."
		},
		videoUrl: "https://www.youtube.com/embed/jZeSh5alXvM"
	},
	{
		id: 2,
		images: [
			"img/ifsmilecapture.webp",
			"img/ifsmilecapture2.webp"
		],
		title: "if (smile) { capture(); }",
		description: {
			ja: "カメラの歴史において，撮影の瞬間は技術的にも芸術的にも重要な意味を持つ。従来のカメラでは，シャッターを切ること自体が意図的な行動であり，撮影者の選択とタイミングに依存していた。しかし，デジタルカメラの進化により，撮影行為は自動化にも対応し，そのプロセスにおいて人間の判断が必要ない撮影が可能になった。スマイルシャッターの創造的誤用を通じて，カメラの内部で何が行われているのか，その隠れた処理の一端に触れ，カメラが見落としてきた可能性や，カメラという機器に対する再解釈を促す。",
			en: "In the history of the camera, the moment of taking a picture is of great technical and artistic significance. With conventional cameras, releasing the shutter itself was a deliberate act, dependent on the choice and timing of the photographer. However, with the evolution of digital cameras, the act of photographing can now be automated, making it possible to take pictures without the need for human judgment in the process. Through the creative misuse of the smiley shutter, we can touch upon what is going on inside the camera and some of its hidden processes, and we can see the possibilities that have been overlooked by the camera, as well as reinterpret the camera as a device. It also encourages us to reinterpret the camera as an instrument."
		},
		videoUrl: "https://www.youtube.com/embed/MJ2C1aNkvAc"
	},
	{
		id: 3,
		images: [
			"img/hackognize1.webp",
			"img/hackognize2.webp"
		],
		title: "sound: affect(object)",
		description: {
			ja: "本作品は言葉と音の複雑な関係性に焦点を当て，音声認識システムの誤認識を利用したサウンド・インスタレーション作品です。本作品に配置されている音声認識アシスタントのSiriは空間に配置されたスピーカーから流れる音に反応します。音声認識システムはあくまで与えられた音を元に判断し，返答しますが，それらは作者が意図的に音声認識システムが誤認識するように選んだ音であるため，システムは読み間違えて食い違っていきます。作品の体験を通して，鑑賞者は本来の言葉の意味や関係性などを再考することになります。",
			en: "Focusing on the complex relationship between words and sound, this work is a sound installation utilizing the misrecognition of a voice recognition system. Siri, the voice recognition assistant installed in this work, responds to sounds played from speakers placed in the space. The voice recognition system makes judgments and replies based on the given sounds, but because these are sounds that the author intentionally chose for the voice recognition system to misrecognize, the system misreads and misinterprets the sounds. Through the experience of the work, the viewer is encouraged to reconsider the meaning of the original words and their relationship to each other."
		},
		videoUrl: "https://www.youtube.com/embed/zflnMjwD9-0"
	}
];

function ScrollToTop() {
	const [isVisible, setIsVisible] = useState(false);

	const toggleVisibility = () => {
		if (window.pageYOffset > window.innerHeight / 2) {
			setIsVisible(true);
		} else {
			setIsVisible(false);
		}
	};

	const scrollToTop = () => {
		const scrollStep = -window.scrollY / (1000 / 15); // 1秒でスクロール完了
		const scrollAnimation = () => {
			if (window.scrollY !== 0) {
				window.scrollBy(0, scrollStep);
				requestAnimationFrame(scrollAnimation);
			}
		};
		requestAnimationFrame(scrollAnimation);
	};

	useEffect(() => {
		window.addEventListener("scroll", toggleVisibility);
		return () => {
			window.removeEventListener("scroll", toggleVisibility);
		};
	}, []);

	return (
		<button
			className={`scroll-to-top ${isVisible ? "visible" : ""}`}
			onClick={scrollToTop}
			aria-label="トップへスクロール"
		>
			▲
		</button>
	);
}

function ImageSlideshow({ images }) {
	const [currentIndex, setCurrentIndex] = useState(0);

	useEffect(() => {
		const interval = setInterval(() => {
			setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
		}, 3000);

		return () => clearInterval(interval);
	}, [images]);

	return (
		<div className="media-container">
			{images.map((image, index) => (
				<img
					key={index}
					src={image}
					alt={`Slide ${index + 1}`}
					style={{
						opacity: index === currentIndex ? 1 : 0,
						position: index === 0 ? 'relative' : 'absolute',
						top: 0,
						left: 0,
						width: '100%',
						transition: 'opacity 0.5s ease-in-out'
					}}
				/>
			))}
		</div>
	);
}

function Sotsuten2025() {
	const [language, setLanguage] = useState("ja");

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<div className="project-container">
			<ScrollToTop />
			<button
				onClick={() => setLanguage(language === "ja" ? "en" : "ja")}
				className="lang-button"
			>
				{language === "ja" ? "EN" : "JA"}
			</button>

			<header className="project-header">
				<h1 className="project-title">reinterpret(system) &#123; through misuse &#125;;</h1>
				<div className="project-description">
					<p>
						{language === "ja" ? (
							"近年，テクノロジーの発展により選択肢が増え，深く考えることなく選択することができる時代となった．このような背景の中で，本作品郡はシステムの不可知な部分に意識を向けさせることを⽬的とする．特に，技術的ブラックボックスとして知られる既製品のシステムに対して，内部から⼲渉することが難しい状況では，創造的誤⽤を通じてその不可知性を明らかにできると考える．創造的誤⽤をしたシステムを利⽤し，表現することで，単⼀の視点や既成概念にとらわれず，深い洞察や批判的思考を促し，テクノロジーの裏側を多⾓的に捉えられる．本作品群ではその有効性を検証し，ユーザーがシステムに⼲渉し，創作⾏為を⾏うための⽅法を模索した．"
						) : (
							"In recent years, the development of technology has increased the number of choices available, we have entered an era where we can make choices without thinking deeply.Against this background, this series of works aims to focus on the unknowable parts of the system. In particular, for ready-made systems known as technical black boxes, it is difficult to intervene from the inside, but it is believed that their unknowability can be revealed through creative misuse. By using a system that has been creatively misused to express itself, it is possible to avoid being bound by a single perspective or established concepts, and to promote deep insight and critical thinking, enabling a multifaceted view of the technology behind the scenes. In this series of works, the effectiveness of this approach was verified, and methods were explored for users to intervene in the system and engage in creative acts. In this series of works, the effectiveness of this approach was verified, and methods were explored for users to intervene in the system and engage in creative acts."
						)}
					</p>
				</div>
			</header>

			{artworksData.map((artwork, index) => (
				<section key={artwork.id} className="project-section">
					<h2 className="project-section-title">{artwork.title}</h2>
					
					<ImageSlideshow images={artwork.images} />

					<div className="project-description">
						<p>{artwork.description[language]}</p>
					</div>

					<div className="video-wrapper">
						<iframe
							src={artwork.videoUrl}
							title={artwork.title}
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
							allowFullScreen
						/>
					</div>

					{index < artworksData.length - 1 && <hr />}
				</section>
			))}

			<div className="credit-section">
				<h3>Credit:</h3>
				<p>
					<a href="/">Rintaro Fujita (Creator)</a>
					<br />
					<a href="https://scottallen.ws/biography">Scott Allen (Advisor)</a>
				</p>
			</div>
		</div>
	);
}

export default Sotsuten2025;
