import React, { useEffect, useRef, useState } from "react";
import * as THREE from "three";
import { STLLoader } from "three/examples/jsm/loaders/STLLoader"; // Import STLLoader
import { Link } from "react-router-dom";

function STLViewer() {
  const canvasRef = useRef(null);
  const [angleIncrement, setAngleIncrement] = useState(0.003); // Default angle increment
  const [ambientLightColor, setAmbientLightColor] = useState(
    new THREE.Color("black")
  ); // Default ambient light color as a THREE.Color objectF

  useEffect(() => {
    const canvas = canvasRef.current;
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(
      65,
      canvas.offsetWidth / canvas.offsetHeight,
      0.6,
      1000
    );
    const renderer = new THREE.WebGLRenderer({
      canvas,
      antialias: true, // Enable antialiasing
    });
    renderer.setSize(canvas.offsetWidth, canvas.offsetHeight);

    // Set background color
    scene.background = new THREE.Color("white");
    renderer.setClearColor(0x2f2f2f, 1);

    // Lights
    const light = new THREE.DirectionalLight("white", 3);
    light.position.set(7, 5, 5);
    scene.add(light);

    const ambientLight = new THREE.AmbientLight(ambientLightColor, 3); // Use the color state
    scene.add(ambientLight);

    // STL Loader
    const loader = new STLLoader();
    loader.load(
      "/models/rintaroface2.stl",
      (geometry) => {
        console.log("STL Loaded:", geometry);

        // Create material and mesh
        const material = new THREE.MeshStandardMaterial({ color: 0xffffff });
        const mesh = new THREE.Mesh(geometry, material);
        scene.add(mesh);

        mesh.position.set(0, 0, 0);
        mesh.scale.set(1.2, 1.2, 1.2);

        // Rotate model around X-axis to make it upright (90 degrees)
        mesh.rotation.x = Math.PI / 2; // 90 degrees rotation on the X-axis
        mesh.rotation.y = Math.PI;

        // Compute bounding box
        geometry.computeBoundingBox();
        const center = new THREE.Vector3();
        geometry.boundingBox.getCenter(center);

        const size = new THREE.Vector3();
        geometry.boundingBox.getSize(size);
        const maxSize = Math.max(size.x, size.y, size.z);
        const cameraDistance = maxSize * 1.3;

        camera.position.set(center.x + cameraDistance, center.y, center.z);
        camera.lookAt(center);

        // Light position
        light.position.set(
          camera.position.x,
          camera.position.y,
          camera.position.z
        );
        light.target.position.set(center.x, center.y, center.z);

        let angle = 1.35;
        const radius = cameraDistance;
        const offset = new THREE.Vector3(0, 3, 0);

        // フレームレート制御のための変数
        let lastTime = 0;
        const targetFPS = 180;
        const frameInterval = 1000 / targetFPS;

        // Random event for every 100th reload
        if (Math.random() < 0.001) {
          // 1% chance (1000 times)
          // Change ambient light color to rainbow
          setAmbientLightColor(
            new THREE.Color(Math.random(), Math.random(), Math.random())
          ); // Random RGB values

          // Speed up the angle increment
          setAngleIncrement(0.003); // Faster rotation speed
        }

        // アニメーション
        function animate(currentTime) {
          requestAnimationFrame(animate);

          // フレームレート制御
          if (currentTime - lastTime < frameInterval) return;
          lastTime = currentTime;

          angle += angleIncrement;

          camera.position.x = center.x + Math.cos(angle) * radius;
          camera.position.z = center.z + Math.sin(angle) * radius;
          camera.position.y = center.y + offset.y;

          camera.lookAt(center);

          light.position.copy(camera.position);
          renderer.render(scene, camera);
        }

        // パフォーマンス最適化のための設定
        renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
        renderer.powerPreference = "high-performance";

        animate(0);
      },
      (xhr) => {
        // console.log((xhr.loaded / xhr.total) * 100 + "% loaded");
      },
      (error) => {
        console.error("An error happened", error);
      }
    );

    const handleResize = () => {
      const width = canvas.offsetWidth;
      const height = canvas.offsetHeight;
      renderer.setSize(width, height);
      camera.aspect = width / height;
      camera.updateProjectionMatrix();
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      scene.traverse((child) => {
        if (child.isMesh) {
          child.geometry.dispose();
          if (child.material.isMaterial) {
            child.material.dispose();
          }
        }
      });
      renderer.dispose();
    };
  }, [ambientLightColor, angleIncrement]); // Dependency on light color and angle increment

  return <canvas ref={canvasRef} />;
}

export { STLViewer };

function Main() {
  const [language, setLanguage] = useState("en");
  const [expandedSections, setExpandedSections] = useState({
    webWorks: false,
    artWorks: false,
    projects: false,
    conference: false,
    awards: false,
    lecture: false
  });
  const [showScrollTop, setShowScrollTop] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const viewportHeight = window.innerHeight;
      const pageHeight = document.documentElement.scrollHeight;
      // ページの3分の1をスクロールした時点で表示
      setShowScrollTop(scrollY > viewportHeight * 0.3);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToTop = () => {
    const duration = 1000; // スクロールにかかる時間（ミリ秒）
    const start = window.scrollY;
    const startTime = performance.now();

    const animateScroll = (currentTime) => {
      const elapsed = currentTime - startTime;
      const progress = Math.min(elapsed / duration, 1);
      
      // イージング関数（ease-out）を適用
      const easeProgress = 1 - Math.pow(1 - progress, 3);
      
      window.scrollTo(0, start * (1 - easeProgress));

      if (progress < 1) {
        requestAnimationFrame(animateScroll);
      }
    };

    requestAnimationFrame(animateScroll);
  };

  const toggleSection = (section) => {
    setExpandedSections(prev => ({
      ...prev,
      [section]: !prev[section]
    }));
  };

  const descriptions = {
    en: (
      <>
        <p>
          From Tokyo, Japan. Graduated from Kyoto Seika University. I
          have been involved in a wide range of activities, including web
          service development and electronic construction.
          <br />
          <br />
          I joined the Latent Media Lab in 2023, where I have been working with
          media art since my junior year, creating works that interact with
          existing products.
          <br />
          <br />
          In 2024, I participated in an exchange program at the IDC School of
          Design, IIT Bombay, and engaged in research and creative production at
          the Design for Future Lab.
          <br />
          <br />I have exhibited my work at IPSJ INTERACTION, Maker Faire,
          INTERCOLLEGE SONIC ARTS FESTIVAL, SIGGRAPH ASIA 2024, and other
          external exhibitions and seminar exhibitions. I plan to actively
          participate in exhibitions both in Japan and abroad in the future.
        </p>
      </>
    ),
    ja: (
      <>
        <p>
          東京都出身．京都精華大学卒．活動はWebサービス開発や電子工作など
          多岐にわたる． <br />
          <br />
          2023年よりLatent Media Lab.に所属．3年時よりメディアアートに触れ，
          既存の製品に対して干渉していく制作を行っている．
          <br />
          <br />
          2024年にIIT Bombay, IDC School of Designに交換留学し，Design for
          Future Labにおいて研究および制作活動に従事した．
          <br />
          <br />
          情報処理学会 INTERACTIONやMaker Faire，INTERCOLLEGE SONIC ARTS
          FESTIVAL，SIGGRAPH ASIA 2024などでの外部展示，
          ゼミ展示などこなし，今後も国内外問わず，展示会への積極的な参加を目論む．
        </p>
      </>
    ),
  };

  return (
    <main>

      <section className="profile">
        <h2 className="section">Profile</h2>
        <div className="img">
          <STLViewer />
        </div>
        <h3>Rintaro FUJITA</h3>
        <button
          onClick={() => setLanguage(language === "en" ? "ja" : "en")}
          className="lang-button"
        >
          {language === "en" ? "JA" : "EN"}
        </button>
        <div className="description">{descriptions[language]}</div>
      </section>

      <div className="section-container">
        <div className="section-header" onClick={() => toggleSection('webWorks')}>
          <h2 className="section">Web Works</h2>
          <span className={`arrow ${expandedSections.webWorks ? 'expanded' : ''}`}>▼</span>
        </div>
        <div className={`section-content ${expandedSections.webWorks ? 'expanded' : ''}`}>
          <section>
            <ul>
              <li>
                <a
                  href="https://media.kyoto-seika.ac.jp/latentmedia/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="links"
                >
                  LatentMediaLab. - Web Cording use WordPress
                </a>
              </li>
              <br />
              <li>
                <a
                  href="https://librikyotoseika.github.io/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Libri - Web Cording
                </a>
              </li>
              <br />
              <li>
                <a
                  href="https://rintarofujita.tech"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  RintaroFujitaPortfolio - Web Cording use React
                </a>
              </li>
              <br />
              <li>
                <a
                  href="https://media.kyoto-seika.ac.jp/latentmedia/exhibition1h2023/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  LatentMediaLab.2023 1H "灯火" Event Site - Web Cording
                </a>
              </li>
              <br />
              <li>
                <a
                  href="https://media.kyoto-seika.ac.jp/latentmedia/exhibition2h2023/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  LatentMediaLab.2023 2H "Co-" Event Site - Web Create use Svelte
                </a>
              </li>
              <br />
              <li>
                <a
                  href="https://caaccs.kyoto-seika.ac.jp/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Kyoto Seika University CAACCS Website - Web Cording use WordPress
                </a>
              </li>
            </ul>
          </section>
        </div>

        <div className="section-header" onClick={() => toggleSection('artWorks')}>
          <h2 className="section">Art Works</h2>
          <span className={`arrow ${expandedSections.artWorks ? 'expanded' : ''}`}>▼</span>
        </div>
        <div className={`section-content ${expandedSections.artWorks ? 'expanded' : ''}`}>
          <section>
            <ul>
              <li>
                <Link to="/bitshape">Bitshape</Link>
              </li>
              <br />
              <li>
                <Link to="/pulsatiolitoris">Pulsatio Litoris</Link>
              </li>
              <br />
              <li>
                <Link to="/decieve">Deceive</Link>
              </li>
              <br />
              <li>
                <Link to="/hackognition">sound: affect(object)</Link>
              </li>
              <br />
              <li>
                <Link to="/recognitioncapturescene">
                  Recognition: capture(scene)
                </Link>
              </li>
              <br />
              <li>
                <Link to="/ifsmilecapture">
                  if (smile) &#123; capture(); &#125;
                </Link>
              </li>
            </ul>
          </section>
        </div>

        <div className="section-header" onClick={() => toggleSection('projects')}>
          <h2 className="section">Projects</h2>
          <span className={`arrow ${expandedSections.projects ? 'expanded' : ''}`}>▼</span>
        </div>
        <div className={`section-content ${expandedSections.projects ? 'expanded' : ''}`}>
          <section>
            <ul>
              <li>
                <a
                  href="https://media.kyoto-seika.ac.jp/ddnd/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Designing Danbaul & Dungeon - Make Cam System
                </a>
              </li>
              <br />
              <li>
                <Link to="technosomen">TechnoSOMEN - Hardware Create</Link>
              </li>
              <br />
              <li>
                <Link to="tongueprotecter">Tongue Protecter - Hardware Create</Link>
              </li>
              <br />
              <li>
                <Link to="technoandon">TechnoANDON - Hardware Create</Link>
              </li>
              <br />
              <li>
                <a
                  href="https://github.com/RintaroFujita"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  GitHub Page in Other Projects
                </a>
              </li>
            </ul>
          </section>
        </div>

        <div className="section-header" onClick={() => toggleSection('conference')}>
          <h2 className="section">Conference / Exhibition</h2>
          <span className={`arrow ${expandedSections.conference ? 'expanded' : ''}`}>▼</span>
        </div>
        <div className={`section-content ${expandedSections.conference ? 'expanded' : ''}`}>
          <section>
            <ul>
              <li>
                <a
                  href="https://media.kyoto-seika.ac.jp/latentmedia/exhibition1h2023/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  LatentMediaLab.2023 1H "灯火"
                </a>
              </li>
              <br />
              <li>
                <a
                  href="https://www.tokyoartbeat.com/events/-/Homeworks-2023-New-Unspoiled-Landscape/blend-studio/2023-11-25"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  HOMEWORKS 2023 "あたらしい原風景"
                </a>
              </li>
              <br />
              <li>
                <a
                  href="https://ic.jssa.info/index_2023.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  INTERCOLLEGE SONIC ARTS FESTIVAL 2023
                </a>
              </li>
              <br />
              <li>
                <a
                  href="https://www.interaction-ipsj.org/proceedings/2024/data/bib/2B-38.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  IPSJ Interaction 2024
                </a>
              </li>
              <br />
              <li>
                <a
                  href="https://media.kyoto-seika.ac.jp/latentmedia/exhibition2h2023/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  LatentMediaLab.2023 2H "Co-"
                </a>
              </li>
              <br />
              <li>
                <a
                  href="https://makezine.jp/event/makers-mfk2024/m0050/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Maker Faire Kyoto 2024
                </a>
              </li>
              <br />
              <li>
                <a
                  href="https://makezine.jp/event/makers-mft2024/m0079/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Maker Faire Tokyo 2024
                </a>
              </li>
              <br />
              <li>
                <a
                  href="https://asia.siggraph.org/2024/presentation/?id=artg_248&sess=sess183"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  SIGGRAPH Asia 2024 Art Gallery
                </a>
              </li>
              <br />
              <li>
                <a
                  href="https://hack.wired.jp/finalists/index.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  CREATIVE HACK AWARD 2024
                </a>
              </li>
              <br />
              <li>
                <a
                  href="https://peatix.com/event/4210013"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  HOMEWORKS 2024
                </a>
              </li>
              <br />
              <li>
                <a
                  href="https://ic.jssa.info/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  INTERCOLLEGE SONIC ARTS FESTIVAL 2024
                </a>
              </li>
              <br />
              <li>
                <a
                  href="https://www.interaction-ipsj.org/2025/web-catalog/#poster3"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  IPSJ Interaction 2025
                </a>
              </li>
              <br />
              <li>
                <a
                  href="https://media.kyoto-seika.ac.jp/latentmedia/exhibition2h2024/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  LatentMediaLab.2024 2H "メディアカラ作品展"
                </a>
              </li>
            </ul>
          </section>
        </div>

        <div className="section-header" onClick={() => toggleSection('awards')}>
          <h2 className="section">Awards</h2>
          <span className={`arrow ${expandedSections.awards ? 'expanded' : ''}`}>▼</span>
        </div>
        <div className={`section-content ${expandedSections.awards ? 'expanded' : ''}`}>
          <section>
            <ul>
              <li>
                <a
                  href="https://www.kyoto-seika.ac.jp/news/2024/0514_1.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Maker Faire Kyoto 2024 "TechnoANDON"- Jury Award
                </a>
              </li>
              <br />
              <li>
                <a
                  href="https://hack.wired.jp/finalists/index.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  CREATIVE HACK AWARD 2024 - Finalist
                </a>
              </li>
            </ul>
            <br />
          </section>
        </div>

        <div className="section-header" onClick={() => toggleSection('lecture')}>
          <h2 className="section">Lecture</h2>
          <span className={`arrow ${expandedSections.lecture ? 'expanded' : ''}`}>▼</span>
        </div>
        <div className={`section-content ${expandedSections.lecture ? 'expanded' : ''}`}>
          <section>
            <ul>
              <li>
                <a
                  href="https://www.instagram.com/p/DFfEQtrA4_H/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Workshop Topic: "Creative Misuse" - Anuva 2025 Lecture Series
                </a>
              </li>
              <br />
            </ul>
          </section>
        </div>
      </div>

      <section>
        <h2 className="section">Contact</h2>
        <ul>
          <li>Mail:</li>
          <li>rinpc356 (at) gmail.com</li>
          <br />
          <li>X:</li>
          <li>
            <a
              href="https://twitter.com/fritta625"
              target="_blank"
              rel="noopener noreferrer"
            >
              Rintaro Fujita
            </a>
          </li>
          <br />
          <li>Instagram:</li>
          <li>
            <a
              href="https://www.instagram.com/r.fujita625/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Rintaro Fujita
            </a>
          </li>
          <br />
          <li>GitHub:</li>
          <li>
            <a
              href="https://github.com/RintaroFujita"
              target="_blank"
              rel="noopener noreferrer"
            >
              Rintaro Fujita
            </a>
          </li>
          <br />
          <li>YouTube:</li>
          <li>
            <a
              href="https://www.youtube.com/@RintaroFujita"
              target="_blank"
              rel="noopener noreferrer"
            >
              Rintaro Fujita
            </a>
          </li>
        </ul>
        <br />
      </section>

      <button
        className={`scroll-to-top ${showScrollTop ? 'visible' : ''}`}
        onClick={scrollToTop}
        aria-label="ページトップへスクロール"
      >
      ▲
      </button>
    </main>
  );
}

export default Main;
