import React, { useState, useEffect } from "react";

const artworksData = [
  {
    id: 1,
    imageUrl: "img/ifsmilecapture.webp",
  },
  {
    id: 2,
    imageUrl: "img/ifsmilecapture2.webp",
  },
];

function Decieve() {
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
	nextSlide();
    }, 5000);

    return () => {
	clearInterval(interval);
    };
  }, [currentSlide]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const nextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % artworksData.length);
  };

  return (
    <div className="slideshow-container">
	<br />
	<div className="slideshow">
	  {artworksData.map((artwork, index) => (
	    <div
		key={artwork.id}
		className={`slide ${index === currentSlide ? "active" : ""}`}
	    >
		<img
		  src={artwork.imageUrl}
		  alt="Artwork"
		  className="artworksimg-technoandon"
		/>
	    </div>
	  ))}
	</div>
	<h2 className="artworkstitle">if (smile) &#123; capture(); &#125;</h2>
	<br />
	<br />
	<div className="artworkscontainer">
	  <p className="artworksdetail">
	  カメラの歴史において，撮影の瞬間は技術的にも芸術的にも重要な意味を持つ．
	  従来のカメラでは，シャッターを切ること自体が意図的な行動であり，撮影者の選択とタイミングに依存していた．
	  しかし，デジタルカメラの進化により，撮影行為は自動化にも対応し，そのプロセスにおいて人間の判断が必要ない撮影が可能になった．
	  スマイルシャッターの創造的誤用を通じて，カメラの内部で何が行われているのか，その隠れた処理の一端に触れ，カメラが見落としてきた可能性や，
	  カメラという機器に対する再解釈を促す．
	  </p>
	  <br />
	  <hr></hr>
	  <br />
	  <p className="artworksdetailen">
	  In the history of the camera, the moment of taking a picture is of great technical and artistic significance.
	  <br/>With conventional cameras, releasing the shutter itself was a deliberate act, dependent on the choice and timing of the photographer.
	  <br/>However, with the evolution of digital cameras, the act of photographing can now be automated, making it possible to take pictures without the need for human judgment in the process.
	  <br/>Through the creative misuse of the smiley shutter, we can touch upon what is going on inside the camera and some of its hidden processes, and we can see the possibilities that have been overlooked by the camera, as well as reinterpret the camera as a device.
	  <br/>It also encourages us to reinterpret the camera as an instrument.
	  </p>
	  <br />
	  <br />
	  <iframe width="350" height="500" src="https://www.youtube.com/embed/MJ2C1aNkvAc?si=unbiW_P1kXx6LIXU" 
	  title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; 
	  encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" 
	  allowfullscreen
	  className="responsive-iframe"></iframe>
	  {/* <iframe
	    width="350px"
	    height="500"
	    src="https://www.youtube.com/embed/jZeSh5alXvM?si=o8OYX7RXchYblfUO"
	    title="YouTube video player"
	    frameborder="0"
	    allow="accelerometer; autoplay; clipboard-write; encrypted-med
	  ia; gyroscope; picture-in-picture; web-share"
	    allowfullscreen
	    className="responsive-iframe"
	  ></iframe> */}
	  <p className="credit">
	    Credit:
	    <br />
	    <a href="/">Rintaro Fujita(Creator)</a>
	    <br />
	    <a href="https://scottallen.ws/biography">Scott Allen(Advisor)</a>
	    <br />
	  </p>
	  <br />
	  {/* <section>
	    <h2 className="section">Conference / Exhibition </h2>
	    <ul>
		<li>
		  <a
		    href="https://asia.siggraph.org/2024/presentation/?id=artg_248&sess=sess183"
		    target="_blank"
		    rel="noopener noreferrer"
		  >
		    SIGGRAPH Asia 2024 Art Gallery
		  </a>
		</li>
		<br/>
		<li>
		  <a
		    href="https://hack.wired.jp/finalists/index.html"
		    target="_blank"
		    rel="noopener noreferrer"
		  >
		    WIRED CREATIVE HACK AWARD 2024 Finalist
		  </a>
		</li>
		<br/>
		<li>
		<a
		  href="https://peatix.com/event/4210013"
		  target="_blank"
		  rel="noopener noreferrer"
		>
		  HOMEWORKS 2024
		</a>
	    </li>
	    </ul>
	  </section> */}
	  <br />
	  <br />
	</div>
	<br />
	<br />
	<br />
    </div>
  );
}

export default Decieve;
